<template>
    <div class="calendar-card">
        <el-calendar :range="range"> </el-calendar>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'CalendarCard',
    components: {},
    data() {
        return {
            // 获取本月开始和结束日期
            range: [
                moment()
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                moment()
                    .endOf('month')
                    .format('YYYY-MM-DD')
            ]
        }
    },
    created() {},
    methods: {}
}
</script>
<style lang="less" scoped>
.calendar-card {
    grid-row-start: span 2;
    // padding: 0.2rem 0.2rem 0 0.2rem;
    box-sizing: border-box;
    /deep/ .el-calendar {
        box-sizing: border-box;
        height: 100%;
        border-radius: 0.6rem;
        background: rgba(255, 255, 255, 0.65);
        padding: 0.2rem;
        .el-calendar__header {
            font-size: 0.24rem;
            color: #606266;
            font-weight: 500;
            padding: 0.12rem 0.2rem;
            .el-calendar__button-group {
                display: none;
            }
        }
        .el-calendar__body {
            padding: 0.06rem 0.08rem 0.35rem;
            thead {
                th {
                    font-size: 0.18rem;
                    color: #c0c4cc;
                }
            }
            .el-calendar-table__row {
                td {
                    border: none;
                    height: 0.44rem;
                    width: 0.44rem;
                    text-align: center;
                    .el-calendar-day {
                        text-align: center;
                        // margin: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        > span {
                            display: inline-block;
                            width: 0.36rem;
                            height: 0.36rem;
                            line-height: 0.36rem;
                            border-radius: 50%;
                        }
                    }
                    .el-calendar-day:hover {
                        background: transparent;
                    }
                    .el-calendar-day > span:hover {
                        color: #fff;
                        background-color: #c0c4cc;
                    }
                }
                td.current {
                    color: #606266;
                    font-size: 0.18rem;
                    font-weight: 600;
                }
                td.is-today {
                    .el-calendar-day {
                        > span {
                            color: #ffffff;
                            background: #df5a68;
                        }
                    }
                }

                td.is-selected {
                    background: transparent;
                }
                .el-calendar-day {
                    padding: 0;
                    height: 0.36rem;
                    width: 0.36rem;
                    text-align: center;
                    line-height: 0.36rem;
                }
            }
        }
    }
}
</style>
